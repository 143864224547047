import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import { v4 as uuidv4 } from 'uuid';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TotalesDetalle from "./TotalesDetalle";
import CarritoDetalle from "./CarritoDetalle";
import { LevantarDialogActions, CreacionPedido, GuardarJsonTocken } from "../../../state/reducers/enviarDatosReducers";
import { vaciarCarritoActions } from "../../../state/reducers/carritoReducers"
import BackdropCotizar from "../../../components/ButtonCarr/Backdrop";
import AlertCarr from "./Alertcarrito";
import AlertCarrEnvio from "./AlertcarritoEnvio";
import ReCAPTCHA from "react-google-recaptcha";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    quantity: {
        width: 100,
        marginRight: 30,
        marginLeft: 10,
        "& input": {
            textAlign: "right",
        },
    },
    card: {
        transition: "all 400ms ease-in-out",
        width: 200,
        height: 200,
    }
}));

const Detalle = ({ productos, identificador, activeStep, handleBack, handleNext }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const enviarDatos = useSelector(state => state.enviarDatos)
    const TEST_SITE_KEY = "6Lcnv4IbAAAAAIuNeKgyLPLTKgqBt0nX_ntJ8bXi";
    ////UUID
    useEffect(() => {
        let Tocken = localStorage.getItem("Tocken");
        if (productos.length != 0 && Tocken === null) {
            dispatch(GuardarJsonTocken(uuidv4()));
        }
    }, [])
    /////
    const data = useStaticQuery(graphql`
    query MyQuery {
        allCostoEnvioAllmunicipiosJson {
          edges {
            node {
              Departamento
              municipios {
                municipio
                PrecioEnvio
              }
            }
          }
        }
      }
  `)
    //////Encabezado
    let {
        allCostoEnvioAllmunicipiosJson
    } = data
    const Depart = allCostoEnvioAllmunicipiosJson;
    const rrecorrerDe = Depart.edges.map(e => e.node)
    const DepartFilter = allCostoEnvioAllmunicipiosJson;
    const rrecorrerDepartamentoFiltrado = DepartFilter.edges.map(e => e.node)

    const [Datos, setDatos] = useState({
        empresa: "Mi tiendita Megax",
        telefono: "",
        nombre: "",
        direccion: "",
        correo: "",
        comentario: "",
        u_DoctoNIT: "CF",
        nombrenit: "",
        departamento: "Guatemala",
        municipio: "",
        metodoenvio: 0,
        metodopago: "contraentrega",
        TipoDoc: "Cotizacion",
        DireccionFactura: "",
    })

    console.log(Datos);
    const { telefono, nombre, direccion, correo, departamento, municipio, nombrenit, comentario, u_DoctoNIT, metodoenvio, metodopago, DireccionFactura } = Datos
    const [boolTelefono, setTelefono] = useState(false)
    const [Facturacion, setFacturacion] = useState(false);
    const handleChangeFacturacion = (event) => {
        setFacturacion(event.target.checked);
        if (event.target.checked) {
            setDatos({ ...Datos, u_DoctoNIT: "" })
        } else {
            setDatos({ ...Datos, u_DoctoNIT: "CF", nombrenit: "", DireccionFactura: "" })
            setChecked(false);
        }
    };

    const onChangeFormulario = e => {
        setDatos({
            ...Datos,
            [e.target.name]: e.target.value,
        })
        if ((telefono, nombre, correo)) {
        }
    }
    const correoValido = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
    useEffect(() => {
        ValidatorForm.addValidationRule("boolC", value => {
            const numeroValido = RegExp(
                /^[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}$/
            )
            setTelefono(numeroValido.test(value))
            if (numeroValido.test(value)) {
                return true
            } else {
                return false
            }
        })
    }, [telefono]);

    const Filtrarmunicipios = rrecorrerDepartamentoFiltrado.filter((filtrar) => {
        return filtrar.Departamento === departamento
    });
    const Encontrados = Filtrarmunicipios.map(e => e.municipios);

    const [stateSwitch, setState] = React.useState({
        checkedA: true,
        checkedB: false,
    });



    const handleChange = (event) => {
        if (event.target.name === "checkedB") {
            setState({ ...stateSwitch, checkedA: false, checkedB: event.target.checked });
        } else {
            setState({ ...stateSwitch, checkedA: event.target.checked, checkedB: false });
        }
    };
    const [checked, setChecked] = React.useState(false);

    const handleChangeNombreFactura = (event) => {
        if (event.target.checked) {
            setChecked(event.target.checked);
            setDatos({ ...Datos, nombrenit: nombre, DireccionFactura: direccion })
        } else {
            setChecked(event.target.checked);
            setDatos({ ...Datos, nombrenit: "", DireccionFactura: "" })
        }
    };

    const [stateGeneral, setStateRecaptcha] = useState({
        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
    });
    const handleChangeRecaptcha = (value) => {
        setStateRecaptcha({ value });
    
        if (value === null) setStateRecaptcha({ expired: "true" });
    };
    const asyncScriptOnLoad = () => {
        setStateRecaptcha({ callback: "called!" });
    };

    // useEffect(() => {
    //     setDatos({ ...Datos, metodopago: stateSwitch.checkedB ? "Trasferencia" : "contraentrega" })
    // }, [stateSwitch])

    const enviarFormulario = e => {
        e.preventDefault()
        let Tocken = localStorage.getItem("Tocken");
        dispatch(LevantarDialogActions(true))
        dispatch(CreacionPedido(enviarDatos.subtotal, enviarDatos.total, Datos, productos, Tocken != null ? Tocken : "", enviarDatos.envio,stateGeneral.value));
    }
    return (<>

        <ValidatorForm onSubmit={enviarFormulario}>
            <Paper elevation={2} >
                {identificador === 1 ? (
                    <div style={{
                        borderRadius: 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 5,
                        paddingBlockEnd: 15,
                    }}> <CarritoDetalle prod={productos} /></div>
                ) : identificador === 2 ? (<div style={{
                    borderRadius: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBlockEnd: 15,
                }}>
                    <AlertCarr />
                    <h4 className="event-title m-0 text-primary font-normal">
                        Datos
                    </h4>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                helperText="*Nombre Completo"
                                name="nombre"
                                value={nombre}
                                onChange={onChangeFormulario}
                                validators={["required"]}
                                errorMessages={["este campo es obligatorio"]}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                helperText="*Telefono"
                                value={telefono}
                                name="telefono"
                                onChange={onChangeFormulario}
                                validators={["boolC", "required"]}
                                errorMessages={[
                                    "Numero no valido",
                                    "este campo es obligatorio",
                                ]}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                helperText="*Correo"
                                name="correo"
                                value={correo}
                                onChange={onChangeFormulario}
                                validators={["required", "isEmail"]}
                                errorMessages={[
                                    " este campo es obligatorio ",
                                    "el correo electrónico no es válido ",
                                ]}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <h4 className="event-title m-0 text-primary font-normal">
                                Datos para la Entrega
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextValidator
                                helperText="Direccion Exacta"
                                name="direccion"
                                value={direccion}
                                onChange={onChangeFormulario}
                                fullWidth
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="size-small-standard"
                                options={rrecorrerDe}
                                getOptionLabel={(option) => option.Departamento}
                                onChange={(event, value) => setDatos({ ...Datos, departamento: value ? value.Departamento : "" })}
                                defaultValue={rrecorrerDe[6]}
                                renderInput={(params) =>
                                    <TextField {...params} helperText="Departamentos" margin="normal" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {Encontrados != "" &&
                                (<Autocomplete
                                    options={Encontrados[0]}
                                    getOptionLabel={(option) => option.municipio}
                                    id=""
                                    onChange={(event, value) => setDatos({ ...Datos, metodoenvio: value ? value.PrecioEnvio : 0, municipio: value ? value.municipio : "" })}
                                    renderInput={(params) =>
                                        <TextField {...params} helperText="municipio" margin="normal" />}
                                />)
                            }
                        </Grid>
                        {!Facturacion && (<Grid item xs={12} sm={6}>
                            <TextValidator
                                helperText="NIT (Número de Identificación Tributaria)"
                                name="u_DoctoNIT"
                                value={u_DoctoNIT}
                                onChange={onChangeFormulario}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                autoFocus
                            />
                        </Grid>
                        )}
                        {Facturacion && (
                            <Grid item xs={12} sm={6} />
                        )}
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={Facturacion}
                                        onChange={handleChangeFacturacion}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Ingresar datos para la facturacion"
                            />
                        </Grid>
                        {Facturacion && (<>
                            <Grid item xs={12} sm={6}>
                                <h4 className="event-title m-0 text-primary font-normal">
                                    Datos para la factura
                                </h4>
                            </Grid>
                            <Grid item xs={12} sm={12} container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={handleChangeNombreFactura} name="checked" color="primary" />}
                                    label="Usar el nombre y direccion Para la Facturacion"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    helperText="NIT (Número de Identificación Tributaria)"
                                    name="u_DoctoNIT"
                                    value={u_DoctoNIT}
                                    onChange={onChangeFormulario}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    helperText="Nombre para la facturacion"
                                    name="nombrenit"
                                    value={nombrenit}
                                    onChange={onChangeFormulario}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextValidator
                                    helperText="Direccion Para la factura"
                                    name="DireccionFactura"
                                    value={DireccionFactura}
                                    onChange={onChangeFormulario}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                        </>)}

                        <Grid item xs={12} sm={12}>
                            <TextValidator
                                helperText="Comentario"
                                name="comentario"
                                value={comentario}
                                onChange={onChangeFormulario}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                    </Grid>


                </div>) : identificador === 3 ? (<div style={{
                    borderRadius: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBlockEnd: 15,
                }}>

                    <h4 className="event-title m-0 text-primary font-normal">
                        Forma Pago
                    </h4>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <div style={{ paddingRight: 20 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={true}
                                        onChange={handleChange}
                                        color="primary"
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                }
                                label="Pago Contraentrega"
                            />

                        </div>
                    </Grid></div>) : <div style={{
                        borderRadius: 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 5,
                        paddingBlockEnd: 15,
                    }}>
                    <AlertCarrEnvio dep={departamento} mun={municipio} />
                    <TotalesDetalle priceEnvio={metodoenvio} prod={productos} dep={departamento} mun={municipio} /></div>}

            </Paper>
            <div style={{
                paddingTop: 15,
            }}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    color="secondary"
                    variant="contained"
                >
                    Regresar
                </Button>
                {identificador === 1 && productos.length > 0 ? (
                    <><Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}

                    >
                        Continuar
                    </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button className="bg-error" onClick={() => dispatch(vaciarCarritoActions())}>
                            vaciar Carrito
                        </Button>
                    </>
                ) : identificador === 2 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={
                            !nombre ||
                            !telefono ||
                            !direccion ||
                            !correoValido.test(correo) ||
                            !departamento || !municipio ||
                            !u_DoctoNIT
                        }

                    >
                        Continuar
                    </Button>
                ) : identificador === 3 ? (
                    <>               
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}

                    >
                        Continuar
                    </Button>
                    </>
                ) : null}
                {(
                    enviarDatos.buttonDialog ? (
                        <BackdropCotizar title="Creando pedido........" />) : identificador === 4 ? (
                            <>
                    
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.button}

                            >
                                Finalizar Pedido
                            </Button>   
                            <ReCAPTCHA
                                 theme="light"
                                 sitekey={TEST_SITE_KEY}
                                 onChange={handleChangeRecaptcha}
                                 asyncScriptOnLoad={asyncScriptOnLoad}
                             />                     
                            </>)
                        : null)}
            </div>
        </ValidatorForm>
    </>
    );
}


export default Detalle;