import React from "react";
import InicioCarrito from "./Index"
export default function getStepContent(props) {
    const {activeStep,handleBack,handleNext}  = props;
    switch (activeStep) {
        case 0:
            return <InicioCarrito identificador={1} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext}/>;
        case 1:
            return <InicioCarrito identificador={2} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext}/>;
        case 2:
            return <InicioCarrito identificador={3} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext}/>;
        case 3:
            return <InicioCarrito identificador={4} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext}/>;
        default:
            return 'Unknown step';
    }
}