import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GuardarTotalSubtotal } from "../../../state/reducers/enviarDatosReducers"
import { useDispatch } from 'react-redux';
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}
export default function SpanningTable({ prod, priceEnvio, dep, mun }) {
  const classes = useStyles();
  const theme = useTheme()


  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const dispatch = useDispatch();
  const { subtotal, delivery, total } = getSummaryPrices({ priceEnvio, prod, dep })
  useEffect(() => {
    dispatch(GuardarTotalSubtotal(subtotal, total, delivery));
  }, [])

  return (
    <Paper className={classes.root}>
      <TableContainer className={isMobile ? classes.container : ""}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Detalle
              </TableCell>
              <TableCell align="right">Precio</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Descripcion</TableCell>
              <TableCell align="right">Cantidad.</TableCell>
              <TableCell align="right">Base</TableCell>
              <TableCell align="right">Precio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prod.map((row) => (
              <TableRow key={row.IdProducto}>
                <TableCell>{row.Descripcion}</TableCell>
                <TableCell align="right">{row.Cantidad}</TableCell>
                <TableCell align="right">{row.BaseDefault === "Y" ? row.Unidad : row.Fardo}</TableCell>
                <TableCell align="right">
                  {row.Base === "Y" ? ccyFormat((row.Cantidad * (row.MultiploUnidad * row.PrecioIVA))) : ccyFormat((row.Cantidad * (row.MultiploFardo * row.PrecioIVA)))}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell colSpan={2}>Subtotal</TableCell>
              <TableCell align="right">{subtotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>envio a {mun}</TableCell>
              <TableCell align="right">{delivery}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell align="right">{total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}


function getSummaryPrices({ priceEnvio, prod, dep }) {
  let TotalDoc = 0;
  let Subtotal = 0;
  prod.map((detail) =>
    Subtotal += detail.Base === "Y" ? (detail.Cantidad * (detail.MultiploUnidad * detail.PrecioIVA)) : (detail.Cantidad * (detail.MultiploFardo * detail.PrecioIVA))
  );
  let envio;

  if (dep != "Guatemala") {
    if (Subtotal > 10000) {
      TotalDoc += Subtotal;
      envio = 0;
    } else {
      TotalDoc += Subtotal + priceEnvio;
      envio = priceEnvio;
    }
  } else {
    if (Subtotal > 450) {
      TotalDoc += Subtotal;
      envio = 0;
    } else {
      TotalDoc += Subtotal + priceEnvio;
      envio = priceEnvio;
    }
  }
  Subtotal = Subtotal.toFixed(2);
  TotalDoc = TotalDoc.toFixed(2);
  return { subtotal: Subtotal, delivery: envio, total: TotalDoc }
}
