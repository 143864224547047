import React from "react"
import { useSelector } from "react-redux";
import DetalleCarrito from "./DetalleCarrito"

const InicioCarrito = ({ identificador, activeStep, handleBack, handleNext }) => {
    const { carrito } = useSelector(state => state.carrito)

    return <DetalleCarrito productos={carrito}
        identificador={identificador}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext} />;
}

export default InicioCarrito;