import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DescriptionAlerts({ dep, mun }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <strong>{dep} </strong> {dep === "Guatemala" ? "si el costo es mayor a 450 el envio es Gratis" : "si el costo es mayor a 10,000 el envio es Gratis"}
      </Alert>
    </div>
  );
}
