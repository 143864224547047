import React from 'react';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TextField from "@material-ui/core/TextField";
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "gatsby"
import ExampleImage from "../../DataGatsbyImage"
import { useDispatch } from 'react-redux';
import { cambioCantidadActions, cambioEmpaqueActions, eliminarCarritoActions } from "../../../state/reducers/carritoReducers";
const useStyles = makeStyles({
    root: {
        width: '100%',
      },
      container: {
        maxHeight: 440,
      },
});
function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}
export default function SpanningTable({ prod }) {
    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
    const { subtotal } = getSummaryPrices({ prod })

    const cambiarEmpaque = (item, carrito, Empaque) => {
        item.Base = Empaque;
        item.BaseDefault = Empaque;
        dispatch(cambioEmpaqueActions(item, carrito));
    };
    const cambioCantidad = (value) => {
        const UpdateCantidadItemA = (e) => {
            if (e.target.value > 0) {
                value.Cantidad = e.target.value;
                dispatch(cambioCantidadActions(value, prod));
            }
        };

        return (
            <TextField
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ min: 1 }}
                margin="none"
                value={value.Cantidad}
                onChange={UpdateCantidadItemA}
                className={classes.quantity}
            />
        );
    };

    return (
        <Paper className={classes.root}>
        <TableContainer className={isMobile?classes.container:""}>
            <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={3}>
                            Detalle
                        </TableCell>
                        <TableCell align="right">Precio</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Imagen</TableCell>
                        <TableCell>Descripcion</TableCell>
                        <TableCell align="right">Cantidad.</TableCell>
                        <TableCell align="right">Base</TableCell>
                        <TableCell align="right">Cambio Base</TableCell>
                        <TableCell align="right">Eliminar</TableCell>
                        <TableCell align="right">Precio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prod.map((row) => (
                        <TableRow key={row.IdProducto}>
                            <TableCell>
                                <Link to={`/producto/${row.ItemCodeAux}/`}>
                                    <ExampleImage
                                        image={row.Imagen}
                                        width={100}
                                        height={100}
                                    />
                                </Link></TableCell>
                            <TableCell>{row.Descripcion}</TableCell>
                            <TableCell align="right"> {cambioCantidad(row)}</TableCell>
                            <TableCell align="right"> {row.Base === "Y" ? row.Unidad : row.Fardo}</TableCell>
                            <TableCell align="right"><IconButton edge="end" aria-label="comments">
                                {row.BaseFardo !== row.BaseUnidad && (
                                    <AutorenewIcon
                                        color="primary"
                                        onClick={() =>
                                            cambiarEmpaque(
                                                row,
                                                prod,
                                                row.Base === "Y" ? "N" : "Y"
                                            )
                                        }
                                    />
                                )}
                            </IconButton></TableCell>
                            <TableCell align="right"><IconButton edge="end" aria-label="comments">
                                <DeleteIcon
                                    color="error"
                                    onClick={() =>
                                        dispatch(
                                            eliminarCarritoActions(
                                                row.Descripcion,
                                                row.CodProducto,
                                                prod
                                            )
                                        )
                                    }
                                />
                            </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                {row.Base === "Y" ? ccyFormat((row.Cantidad * (row.MultiploUnidad * row.PrecioIVA))) : ccyFormat((row.Cantidad * (row.MultiploFardo * row.PrecioIVA)))}
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell rowSpan={3} />
                        <TableCell colSpan={5}>Total</TableCell>
                        <TableCell align="right">{subtotal}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    );
}


function getSummaryPrices({ prod }) {
    let Subtotal = 0;
    prod.map((detail) =>
        Subtotal += detail.Base === "Y" ? (detail.Cantidad * (detail.MultiploUnidad * detail.PrecioIVA)) : (detail.Cantidad * (detail.MultiploFardo * detail.PrecioIVA))
    );
    Subtotal = Subtotal.toFixed(2);

    return { subtotal: Subtotal }
}
